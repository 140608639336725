import SfHeader from '@/components/SfHeader'
import SfMenu from '@/components/SfMenu'
import SfBackground from '@/components/SfBackground'
import FooterSection from '@/components/sections/FooterSection'
import SfMap from '@/components/SfMap'

// import { TimelineMax } from 'gsap'

// var ANIMATION_ELEMENT_TIME = 0.2;
// var ANIMATION_DELAY = ANIMATION_ELEMENT_TIME * 6;

export default {
  name: 'sf-product-page',
  components: {
    SfHeader,
    SfMenu,
    SfBackground,
    FooterSection,
    SfMap
  },
  data () {
    return {
      isOpenMenu: false,
      isShowMap: false,
      fullPageOptions: {
        licenseKey: 'A0B80391-497D4154-8EFB8EFD-FEB00375',
        // onLeave: function (index, nextIndex, direction) {
        //   // console.log(index, nextIndex, direction)
        //   //
        //   // var $fromSection = document.querySelector('.js-section-' + index.index);
        //   // var $toSection = document.querySelector('.js-section-' + nextIndex.index);
        //   //
        //   // var $fromSectionImage = $fromSection.querySelector('.js-section-image');
        //   // var $toSectionImage = $toSection.querySelector('.js-section-image');
        //   //
        //   // var $fromSectionTitle = $fromSection.querySelector('.js-section-title');
        //   // var $toSectionTitle = $toSection.querySelector('.js-section-title');
        //   //
        //   // var $fromSectionDescription = $fromSection.querySelector('.js-section-description');
        //   // var $toSectionDescription = $toSection.querySelector('.js-section-description');
        //   //
        //   // var $fromSectionFooter = $fromSection.querySelector('.js-section-footer');
        //   // var $toSectionFooter = $toSection.querySelector('.js-section-footer');
        //   //
        //   // var $fromSectionAction = $fromSection.querySelector('.js-section-action');
        //   // var $toSectionAction = $toSection.querySelector('.js-section-action');
        //   //
        //   // var $fromSectionCard = $fromSection.querySelector('.js-section-card');
        //   // var $toSectionCard = $toSection.querySelector('.js-section-card');
        //   //
        //   // // var argsFromDown = [
        //   // //   { css: { transform: 'translateY(0)' } },
        //   // //   { css: { transform: 'translateY(-100vh)' } },
        //   // // ];
        //   // // var argsToDown = [
        //   // //   ANIMATION_ELEMENT_TIME,
        //   // //   { css: { transform: 'translateY(100vh)' } },
        //   // //   { css: { transform: 'translateY(0)' } },
        //   // // ];
        //   // // var argsFromUp = [
        //   // //   ANIMATION_ELEMENT_TIME,
        //   // //   { css: { transform: 'translateY(0)' } },
        //   // //   { css: { transform: 'translateY(100vh)' } }
        //   // // ];
        //   // // var argsToUp = [
        //   // //   ANIMATION_ELEMENT_TIME,
        //   // //   { css: { transform: 'translateY(-100vh)' } },
        //   // //   { css: { transform: 'translateY(0)' } }
        //   // // ];
        //   //
        //   // var tl = new TimelineMax({});
        //   // if (direction === 'down') {
        //   //   tl
        //   //     .fromTo($fromSectionTitle, 0.8,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(-100vh)' }, ease: CubicBezier.get('easing') })
        //   //
        //   //     .fromTo($fromSectionDescription, 0.8,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(-100vh)' }, delay: 0.1, ease: CubicBezier.get('easing') },
        //   //       0)
        //   //     .fromTo($fromSectionCard, 0.6,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(-100vh)' }, delay: 0.15, ease: CubicBezier.get('easing') },
        //   //       0)
        //   //     .fromTo($fromSectionFooter, 0.8,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(-100vh)' }, delay: 0.2, ease: CubicBezier.get('easing') },
        //   //       0)
        //   //     .fromTo($fromSectionAction, 0.8,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(-100vh)' }, delay: 0.25, ease: CubicBezier.get('easing') },
        //   //       0)
        //   //     .fromTo($fromSectionImage, 0.8,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(-100vh)' }, delay: 0.3, ease: CubicBezier.get('easing') },
        //   //       0)
        //   //     .add('label', 0.3)
        //   //     .fromTo($toSectionTitle, 1,
        //   //       { css: { transform: 'translateY(100vh)' } },
        //   //       { css: { transform: 'translateY(0)' }, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   //     .fromTo($toSectionImage, 1,
        //   //       { css: { transform: 'translate(50vw, 100vh)' } },
        //   //       { css: { transform: 'translate(0, 0)', delay: 0.1 }, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   //     .fromTo($toSectionDescription, 1,
        //   //       { css: { transform: 'translateY(100vh)' } },
        //   //       { css: { transform: 'translateY(0)' }, delay: 0.15, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   //     .fromTo($toSectionCard, 0.8,
        //   //       { css: { transform: 'translateY(100vh)' } },
        //   //       { css: { transform: 'translateY(0)' }, delay: 0.15, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   //     .fromTo($toSectionFooter, 1,
        //   //       { css: { transform: 'translateY(100vh)' } },
        //   //       { css: { transform: 'translateY(0)' }, delay: 0.2, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   //     .fromTo($toSectionAction, 1,
        //   //       { css: { transform: 'translateY(100vh)' } },
        //   //       { css: { transform: 'translateY(0)' }, delay: 0.25, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   // } else {
        //   //   tl
        //   //     .fromTo($fromSectionAction, 0.8,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(100vh)' }, ease: CubicBezier.get('easing') },
        //   //       0)
        //   //     .fromTo($fromSectionFooter, 0.8,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(100vh)' }, delay: 0.1, ease: CubicBezier.get('easing') },
        //   //       0)
        //   //     .fromTo($fromSectionCard, 0.6,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(100vh)' }, delay: 0.15, ease: CubicBezier.get('easing') },
        //   //       0)
        //   //     .fromTo($fromSectionDescription, 0.8,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(100vh)' }, delay: 0.2, ease: CubicBezier.get('easing') },
        //   //       0)
        //   //     .fromTo($fromSectionImage, 0.8,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(100vh)' }, delay: 0.25, ease: CubicBezier.get('easing') },
        //   //       0)
        //   //     .fromTo($fromSectionTitle, 0.8,
        //   //       { css: { transform: 'translateY(0)' } },
        //   //       { css: { transform: 'translateY(100vh)' }, delay: 0.3, ease: CubicBezier.get('easing') })
        //   //     .add('label', 0.3)
        //   //     .fromTo($toSectionAction, 1,
        //   //       { css: { transform: 'translateY(-100vh)' } },
        //   //       { css: { transform: 'translateY(0)' }, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   //     .fromTo($toSectionFooter, 1,
        //   //       { css: { transform: 'translateY(-100vh)' } },
        //   //       { css: { transform: 'translateY(0)' }, delay: 0.1, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   //     .fromTo($toSectionCard, 0.8,
        //   //       { css: { transform: 'translateY(-100vh)' } },
        //   //       { css: { transform: 'translateY(0)' }, delay: 0.1, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   //     .fromTo($toSectionDescription, 1,
        //   //       { css: { transform: 'translateY(-100vh)' } },
        //   //       { css: { transform: 'translateY(0)' }, delay: 0.15, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   //     .fromTo($toSectionImage, 1,
        //   //       { css: { transform: 'translateY(-100vh)' } },
        //   //       { css: { transform: 'translateY(0)', delay: 0.2 }, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   //     .fromTo($toSectionTitle, 1,
        //   //       { css: { transform: 'translateY(-100vh)' } },
        //   //       { css: { transform: 'translateY(0)' }, delay: 0.25, ease: CubicBezier.get('easing') },
        //   //       'label')
        //   // }
        //   // setTimeout(function () {
        //   //   $('.js-number-page').text('0' + nextIndex)
        //   // }, ANIMATION_DELAY * 1000)
        // }
      }
    }
  }
}
