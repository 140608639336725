<template>
  <div class="section" data-section="4" data-anchor="4">
    <div class="container">
      <div class="row">
        <div class="flex flex xs12 l-sm6 l-offset-sm6">
          <h1 class="title mb-8 mb-sm-8--l mb-sm-24 mb-md-24--l mb-xl-32--l">
            Терминалы по перевалке СУГ
          </h1>
          <p class="description mb-0 mb-sm-12--l mb-sm-16 mb-md-16--l mb-md-28 mb-lg-28--l mb-xl-32--l">
            Компания осуществляет перевалку СУГ на базах хранения в Московском регионе
          </p>
          <div class="features mb-28 mb-sm-28--l mb-sm-36 mb-md-32--l mb-md-56 mb-lg-84--l mb-lg-60--l">
            <div class="feature-item">
              <div class="icon mb-4 mb-sm-4--l mb-md-12 mb-lg-12--l">
                <SvgIcon name="database" />
              </div>
              <div class="title">Объём хранения 1400 т</div>
            </div>
            <div class="feature-item">
              <div class="icon mb-4 mb-sm-4--l mb-md-12 mb-lg-12--l">
                <SvgIcon name="truck" />
              </div>
              <div class="title">Доставка спец. транспортом</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon'

export default {
  name: "SecondSection",
  components: {
    SvgIcon
  }
}
</script>

<style lang="scss" scoped>
.section[data-section="4"] {
  ::v-deep .fp-tableCell {
    display: flex;
    flex-direction: column;
  }

  .container {
    .features {
      display: flex;
    }

    .feature-item {
      width: 100%;
      max-width: 50%;
      flex-basis: 50%;
    }

    .feature-item .icon {
      color: #5AACE8;
    }

    .feature-item .icon svg {
      width: 24px;
      height: 24px;

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
        width: 28px;
        height: 28px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
        width: 28px;
        height: 28px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
        width: 32px;
        height: 32px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        width: 32px;
        height: 32px;
      }
    }

    .feature-item .title {
      @extend %h5;
      color: rgba(#FFFFFF, 0.9);
    }
  }
}
</style>
