import SfHeader from '@/components/SfHeader'
import SfMenu from '@/components/SfMenu'

import SvgIcon from '@/components/SvgIcon'

import axios from 'axios'

import 'vue2-datepicker/index.css'

import 'vue2-datepicker/locale/ru'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default {
  name: 'sf-press-center-page',
  components: {
    SfHeader,
    SfMenu,
    SvgIcon
  },
  data () {
    return {
      isOpenMenu: false,
      exchangeRateEuro: {
        value: 0,
        previous: 0
      },
      exchangeRateDollar: {
        value: 0,
        previous: 0
      },
      dateRange: [new Date(2020, 1, 1), new Date(2020, 1, 7)]
    }
  },
  mounted () {
    axios.get('https://www.cbr-xml-daily.ru/daily_json.js')
      .then(response => {
        const infoEUR = response.data.Valute.EUR
        this.exchangeRateEuro.value = infoEUR.Value
        this.exchangeRateEuro.previous = infoEUR.Previous

        const infoUSD = response.data.Valute.USD
        this.exchangeRateDollar.value = infoUSD.Value
        this.exchangeRateDollar.previous = infoUSD.Previous
      })
    axios.get('https://api.twelvedata.com/time_series?symbol=LPG&interval=1day&apikey=8439b937dc484627a11e0163bc5d5a0a')
      .then(response => {
        am4core.useTheme(am4themes_animated);

        var chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.paddingRight = 20;

        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 60;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;

        var series = chart.series.push(new am4charts.CandlestickSeries());
        series.dataFields.dateX = "datetime";
        series.dataFields.valueY = "close";
        series.dataFields.openValueY = "open";
        series.dataFields.lowValueY = "low";
        series.dataFields.highValueY = "high";
        series.tooltipText = "Open: [bold]${openValueY.value}[/]\nLow: [bold]${lowValueY.value}[/]\nHigh: [bold]${highValueY.value}[/]\nClose: [bold]${valueY.value}[/]";

        chart.cursor = new am4charts.XYCursor();

        chart.scrollbarX = new am4core.Scrollbar();
        chart.data = response.data.values
      })
  }
}
