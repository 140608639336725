import Vue from 'vue'
import App from './App.vue'

Sentry.init({
  Vue,
  dsn: "https://b628011a516f4d758563d1c4b9e3e08a@o755322.ingest.sentry.io/5791912",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

import 'fullpage.js/vendors/scrolloverflow'
import VueFullPage from 'vue-fullpage.js'

Vue.config.productionTip = false

import './animate.css'
import './assets/scss/main.scss'

import router from './router'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

try {
  Vue.use(VueFullPage)

  new Vue({
    render: h => h(App),
    router
  }).$mount('#app')
} catch (e) {
  alert(e)
  const div = document.createElement('div')
  div.innerHTML = 'Произошла ошибка!' + e
  document.body.append(div)
}
