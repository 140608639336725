<template>
  <div class="section" data-anchor="5"></div>
</template>

<script>
export default {
name: "FifthSection"
}
</script>

<style scoped>

</style>
