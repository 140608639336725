<template>
  <footer class="sf-footer">
    <div ref="contact-us" class="contact-us">
      <div class="content">
        <div class="row">
          <div class="flex xs12 l-xs12 sm12 l-sm4 md4 title">
            <h1 class="mt-0">
              <span>Свяжитесь</span>
              <span>с нами</span>
            </h1>
          </div>
          <div class="flex xs12 l-xs12 sm12 l-sm4 md4 address warehouse">
            <div class="city">
              Москва
              <span>База хранения</span>
            </div>
            <div class="caption">Адрес</div>
            <div class="value">Московская область, Пушкинский р-н, пос. Лесные поляны, ул. Новая, влад. 7А</div>
<!--            <div class="caption">Телефон</div>-->
<!--            <div class="value"><a href="tel:74953332211">+7 945 333 22 11</a></div>-->
<!--            <div class="caption">Эл. почта</div>-->
<!--            <div class="value mb-16">moscow_info@sfyros.ru</div>-->
            <div class="link-map" @click="() => { isShowMap = true }">
              <img :src="require('@/assets/images/go_to_map.svg')" alt="">
              <div class="text">Смотреть на карте</div>
            </div>
          </div>
          <div class="flex xs12 l-xs12 sm12 l-sm4 md4 address office">
            <div class="city">
              Пермь
              <span>Офис</span>
            </div>
            <div class="caption">Адрес</div>
            <div class="value">шоссе Космонавтов, д. 111, корпус 27, офис 322</div>
            <div class="caption">Телефон</div>
            <div class="value"><a href="tel:+73422162525">8 (342) 216-25-25</a></div>
            <div class="caption">Эл. почта</div>
            <div class="value mb-16"><a href="mailto:sales@sfayros.com">sales@sfayros.com</a></div>
            <div class="link-map">
              <img :src="require('@/assets/images/go_to_map.svg')" alt="">
              <div class="text">Смотреть на карте</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content main">
      <h1>Остались вопросы?</h1>
      <h4>Мы с радостью на них ответим</h4>
      <form action="">
        <div class="textfield">
          <input type="text">
          <label>Как к вам обращаться?</label>
        </div>
        <div class="textfield mb-36 mb-md-40 mb-lg-56">
          <input type="text">
          <label>Номер телефона</label>
        </div>
        <button class="call-me">Перезвоните мне</button>
      </form>
      <div class="spacer"></div>
    </div>
    <div class="bottom-footer">
      <div class="container">
        <div class="row">
          <div class="flex xs6">
            <ul>
              <li><router-link to="/">О компании</router-link></li>
              <li><router-link to="/history">История компании</router-link></li>
              <li><router-link to="/press-center">Пресс-центр</router-link></li>
              <li><router-link to="/products">Продукты</router-link></li>
              <li><router-link to="/contacts">Контакты</router-link></li>
            </ul>
          </div>
          <div class="flex xs6">
            <div class="row">
              <div class="flex xs12 md7 lg6 email">
                <input type="text" placeholder="Введите E-mail">
              </div>
              <div class="flex xs12 md7 lg6 subscriber">
                <button>Узнавать новости</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SfMap :is-show="isShowMap" />
  </footer>
</template>

<script>
import SfMap from '@/components/SfMap'

export default {
  name: 'SfFooter',
  components: {
    SfMap
  },
  data () {
    return {
      isShowMap: false
    }
  },
  computed: {
  },
  mounted () {
    const contactUsHeight = this.$el.querySelector('.contact-us').clientHeight
    this.$el.querySelector('.main.content').style.paddingTop = `${contactUsHeight}px`
  }
}
</script>

<style lang="scss" scoped>
footer.sf-footer {
  position: fixed;
  z-index: 3;
  top: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 300ms ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;

  &.active {
    top: 0;
  }

  .contact-us {
    position: absolute;
    background-color: #FFF;
    box-shadow: 0 0 20px rgba(34, 34, 34, 0.07);
    top: 70px;
    left: 54px;
    right: 54px;
    overflow: hidden;
    z-index: 1;

    @include min-breakpoint('md') {
      left: 87px;
      right: 87px;
      top: 108px;
    }

    @include min-breakpoint('lg') {
      top: 128px;
    }

    .content {
      background: url("~@/assets/images/map-dot.png") no-repeat;
      width: 100%;
      height: 100%;
      padding: 30px 34px 80px;
    }

    .title {
      span {
        display: block;
        color: #284F92;
      }

      span:first-child {
        @extend %h3;
        color: #284F92;
      }
    }

    .address {
      .city {
        @extend %h4;
        color: #284F92;

        span {
          @extend %body-font;
          color: #284F92;
          display: inline-block;
          margin-left: auto;
        }
      }

      .caption {
        @extend %navigation-2;
        color: #B7B7B7;
      }

      .value {
        @extend %body-font;
        color: rgba(#000, 0.9);

        a {
          color: inherit;
          text-decoration: none;
        }
      }

      .link-map {
        display: flex;
        align-items: center;

        .text {
          @extend %h5;
          color: #40A0D7;
        }
      }
    }
  }

  > .content {
    position: absolute;
    top: 127px;
    left: 0;
    right: 0;
    background: #EDF1F5;

    @include min-breakpoint('md') {
      top: 158px;
    }

    @include min-breakpoint('lg') {
      top: 189px;
    }

    h1 {
      color: #284F92;
      text-align: center;
    }

    h4 {
      @extend %h4-regular;
      color: #000000;
      text-align: center;
    }

    form {
      text-align: center;
    }

    .textfield {
      height: 40px;
      width: 100%;
      max-width: 302px;
      position: relative;
      margin: 0 auto;

      @include min-breakpoint('sm') {
        max-width: 426px;
      }

      @include min-breakpoint('md') {
        height: 48px;
      }

      @include min-breakpoint('lg') {
        max-width: 528px;
      }

      input {
        display: block;
        border: none;
        outline: none;
        border-bottom: 1px solid #D8D8D8;
        width: 100%;
        margin: 40px auto 20px;
        padding: 10px 0;
        background-color: transparent;
        text-align: center;
        font-size: 24px;
        font-weight: 300;
        overflow: visible;
      }

      label {
        line-height: 17px;
        text-transform: uppercase;
        color: #B7B7B7;
        display: block;
        font-weight: 300;
        letter-spacing: 1px;
        font-size: 20px;
        position: absolute;
        transition: all .2s ease-in-out;
        top: 22px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
        width: 100%;
      }

      input:focus ~ label {
        top: -20px;
        font-size: 15px;
      }

      input:focus {
        border-bottom: 1px solid #40A0D7;
      }
    }

    .call-me {
      background: #40A0D7;
      color: white;
      width: 315px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
      margin: 30px auto 0;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;

      @include min-breakpoint('md') {
        height: 48px;
      }
    }

    .spacer {
      height: 73px;

      @include min-breakpoint('sm') {
        height: 89px;
      }

      @include min-breakpoint('md') {
        height: 109px;
      }

      @include min-breakpoint('lg') {
        height: 168px;
      }
    }
  }

  .bottom-footer {
    bottom: -550px;
    left: 0;
    right: 0;
    position: absolute;
    height: 400px;
    color: white;
    padding: 50px 0;

    ul {
      list-style: none;

      > li {
        @extend %body-font;
        color: rgba(#FFF, 0.8);
        margin-bottom: 16px;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }

    .email {
      height: 40px;
      width: 100%;
      max-width: 302px;
      position: relative;
      margin: 0 auto;

      @include min-breakpoint('sm') {
        max-width: 426px;
      }

      @include min-breakpoint('md') {
        height: 48px;
      }

      @include min-breakpoint('lg') {
        max-width: 528px;
      }

      input {
        display: block;
        border: none;
        outline: none;
        width: 100%;
        text-align: center;
        font-weight: 300;
        overflow: visible;
        color: white;
        background-color: rgba(255, 255, 255, .2);
        padding: 13px 22px;
        font-size: 20px;
      }
    }

    .subscriber {
      height: 40px;
      width: 100%;

      @include min-breakpoint('md') {
        height: 48px;
      }

      button {
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: transparent;
        border: 2px solid #40A0D7;
        @extend %navigation;
        color: rgba(#FFF, 0.8);
      }
    }
  }
}
</style>
