<template>
  <div class="sf-history-page">
    <SfHeader @toggle="(e) => { isOpenMenu = e }"/>
    <SfMenu :isOpenMenu="isOpenMenu"/>
    <div
      :class="['section-background', { 'hide': isHideBackground }]"
      :style="{ background: backgroundStyle }"
    />
    <full-page :options="options" ref="fullpage">
      <div
        v-for="(text, year) in historyPages"
        :key="year"
        class="section"
        :data-year="year"
      >
        <div class="container">
          <div class="title mb-20 mb-16--l mb-sm-40 mb-sm-0--l mb-md-32 mb-md-0--l mb-lg-0" ref="title">
            <h1 class="sf-history-page__title">История создания компании</h1>
          </div>
          <div class="line" ref="line" />
          <div class="text sf-history-page__text">
            <div v-html="text.text"/>
            <router-link to="/press-center">
              <SfIcon name="quotes" width="32" class="mr-20"/>
              <span>Смотреть котировки</span>
            </router-link>
          </div>
        </div>
      </div>
    </full-page>
<!--    <div class="fixed-title" :style="{ 'left': `${positionTitleLeft}px`, 'top': `${positionTitleTop}px`, 'maxWidth': `${titleWidth}px` }">-->
<!--      <h1 class="sf-history-page__title">История создания компании</h1>-->
<!--    </div>-->
    <div class="timeline" :style="{ 'bottom': `${positionLineBottom}px`, 'left': `${positionLineLeft}px` }">
      <div class="timeline-item is-clickable active" data-number="1" data-section="0" data-year="2001" @click="onTimelineClick">
        <span>2001</span>
      </div>
      <div class="timeline-item" data-number="2"></div>
      <div class="timeline-item is-clickable" data-number="3" data-section="1" data-year="2002" @click="onTimelineClick">
        <span>2002</span>
      </div>
      <div class="timeline-item" data-number="4"></div>
      <div class="timeline-item is-clickable" data-number="5" data-section="2" data-year="2007" @click="onTimelineClick">
        <span>2007</span>
      </div>
      <div class="timeline-item" data-number="6"></div>
      <div class="timeline-item is-clickable" data-number="7" data-section="3" data-year="2011" @click="onTimelineClick">
        <span>2011</span>
      </div>
      <div class="timeline-item" data-number="8"></div>
      <div class="timeline-item is-clickable" data-number="9" data-section="4" data-year="2016" @click="onTimelineClick">
        <span>2016</span>
      </div>
      <div class="timeline-item" data-number="10"></div>
      <div class="timeline-item is-clickable" data-number="11" data-section="5" data-year="2017" @click="onTimelineClick">
        <span>2017</span>
      </div>
      <div class="timeline-item" data-number="12"></div>
      <div class="timeline-item is-clickable" data-number="13" data-section="6" data-year="2018" @click="onTimelineClick">
        <span>2018</span>
      </div>
<!--      <div class="timeline-item" data-number="14"></div>-->
<!--      <div class="timeline-item is-clickable" data-number="15" data-section="7" data-year="2021" @click="onTimelineClick">-->
<!--        <span>2021</span>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import SfHeader from '@/components/SfHeader'
import SfMenu from '@/components/SfMenu'
import SfIcon from '@/components/SfIcon'

import historyPages from '@/pages/history_page'

export default {
  name: "HistoryPage",
  components: {
    SfHeader,
    SfMenu,
    SfIcon
  },
  data () {
    return {
      isOpenMenu: false,
      options: {
        licenseKey: 'A0B80391-497D4154-8EFB8EFD-FEB00375',
        // eslint-disable-next-line no-unused-vars
        onLeave: (origin ,dest) => {
          if (this.isFlag) return
          this.isHideBackground = true
          setTimeout(() => {
            this.currentYear = dest.item.dataset.year
            this.isHideBackground = false
          }, 300)
          // Прокрутка timeline линии
          const newItem = this.$el.querySelector(`.timeline-item[data-section="${dest.index}"]`)
          const root = newItem.closest('.timeline')
          const currentActive = this.$el.querySelector(`.timeline-item[data-section="${origin.index}"]`)
          const newItemNumber = Number(newItem.dataset.number)
          const currentActiveNumber = Number(currentActive.dataset.number)

          currentActive.classList.remove('active')

          if (newItemNumber > currentActiveNumber) {
            for (
                let i = currentActiveNumber + 1, j = 1;
                i < newItemNumber, j < newItemNumber - currentActiveNumber;
                i++, j++
            ) {
              setTimeout(() => {
                const current = root.querySelector(`.timeline-item[data-number="${i}"]`)
                current.classList.add('active')
                setTimeout(() => {
                  current.classList.remove('active')
                }, 100)
              }, 100 * j)
            }
            setTimeout(() => {
              newItem.classList.add('active')
            }, 100 * (newItemNumber - currentActiveNumber))
          } else {
            for (
                let i = currentActiveNumber - 1, j = 1;
                i > newItemNumber, j < currentActiveNumber - newItemNumber;
                i--, j++
            ) {
              setTimeout(() => {
                const current = root.querySelector(`.timeline-item[data-number="${i}"]`)
                current.classList.add('active')
                setTimeout(() => {
                  current.classList.remove('active')
                }, 100)
              }, 100 * j)
            }
            setTimeout(() => {
              newItem.classList.add('active')
            }, 100 * (currentActiveNumber - newItemNumber))
          }
        }
      },
      historyPages,
      currentYear: Object.keys(historyPages)[0],
      isHideBackground: false,
      positionLineLeft: 0,
      positionLineBottom: 0,
      positionTitleLeft: 0,
      positionTitleTop: 0,
      titleWidth: 0,
      isFlag: false
    }
  },
  computed: {
    backgroundStyle () {
      if (this.currentYear == null) {
        return null
      }
      return `url(${require(`@/assets/images/history-page/${this.currentYear}.jpg`)}), radial-gradient(96.79% 178.05% at 27.16% 67.35%, #1668B0 0%, #0B1D37 41.44%, #000000 90.06%)`
    }
  },
  methods: {
    onTimelineClick (e) {
      this.isFlag = true
      const newItem = e.target.closest('.timeline-item')
      const root = newItem.closest('.timeline')
      const currentActive = root.querySelector('.active')
      const newItemNumber = Number(newItem.dataset.number)
      const currentActiveNumber = Number(currentActive.dataset.number)

      currentActive.classList.remove('active')

      if (newItemNumber > currentActiveNumber) {
        for (
          let i = currentActiveNumber + 1, j = 1;
          i < newItemNumber, j < newItemNumber - currentActiveNumber;
          i++, j++
        ) {
          setTimeout(() => {
            const current = root.querySelector(`.timeline-item[data-number="${i}"]`)
            current.classList.add('active')
            setTimeout(() => {
              current.classList.remove('active')
            }, 100)
          }, 100 * j)
        }
        setTimeout(() => {
          newItem.classList.add('active')
          this.isFlag = false
        }, 100 * (newItemNumber - currentActiveNumber))
      } else {
        for (
          let i = currentActiveNumber - 1, j = 1;
          i > newItemNumber, j < currentActiveNumber - newItemNumber;
          i--, j++
        ) {
          setTimeout(() => {
            const current = root.querySelector(`.timeline-item[data-number="${i}"]`)
            current.classList.add('active')
            setTimeout(() => {
              current.classList.remove('active')
            }, 100)
          }, 100 * j)
        }
        setTimeout(() => {
          newItem.classList.add('active')
          this.isFlag = false
        }, 100 * (currentActiveNumber - newItemNumber))
      }

      this.$refs.fullpage.api.moveTo(Number(newItem.dataset.section) + 1)
      this.currentYear = newItem.dataset.year
    }
  },
  mounted () {
    const firstLine = this.$refs.line[0]
    const box = firstLine.getBoundingClientRect()
    this.positionLineLeft = box.left + pageXOffset
    this.positionLineBottom = Math.abs(window.outerHeight - box.top - box.height) / 4

    const firstTitle = this.$refs.title[0]
    const box1 = firstTitle.getBoundingClientRect()
    this.positionTitleLeft = box1.left
    this.positionTitleTop = box1.top
    this.titleWidth = box1.width
  }
}
</script>

<style lang="scss" scoped>
.sf-history-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-blend-mode: multiply, normal;
  background: radial-gradient(96.79% 178.05% at 27.16% 67.35%, #1668B0 0%, #0B1D37 41.44%, #000000 90.06%);

  .section-background {
    @include absolute-fill;
    transition: opacity 300ms ease-in-out;
    background-blend-mode: multiply, normal;
    background-size: cover !important;

    &.hide {
      opacity: 0;
    }
  }

  .container {
    padding: 27px 16px;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 2;

    @include min-breakpoint('sm') {
      padding: 48px 32px;
    }

    @include min-breakpoint('md') {
      padding: 64px 120px;
    }

    @include min-breakpoint('lg') {
      padding: 84px 124px;
    }

    .title {
      grid-row: 1/2;
      grid-column: 2/3;

      @include min-breakpoint-landscape('sm') {
        grid-column: 1/2;
      }
    }

    .line {
      grid-row: 1/3;
      grid-column: 1/2;

      @include min-breakpoint-landscape('sm') {
        grid-row: 2/3;
      }
    }

    .text {
      grid-row: 2/3;
      grid-column: 2/3;

      @include min-breakpoint-landscape('sm') {
        grid-row: 1/3;
      }
    }
  }

  .fixed-title {
    position: fixed;
  }

  &__title {
    @extend %h1;
    color: #40A0D7;
    text-shadow: 0 0 32px rgba(82, 194, 255, 0.5);
    margin: 0;
  }

  &__text {
    color: rgba(#FFF, 0.9);

    &::v-deep > * {
      margin: 0;
    }

    &::v-deep .subtitle {
      @extend %h4;
      color: inherit;
    }

    &::v-deep .regular {
      @extend %body-font;
      color: inherit;
    }

    &::v-deep .orange {
      @extend %h4;
      color: rgba(#F5DF6A, 0.9);
    }

    &::v-deep a {
      display: flex;
      align-items: center;
      @extend %navigation;
      color: rgba(#FFF, 0.5);
      text-decoration: none;
      transition: all 300ms ease-in-out;

      &:hover {
        color: rgba(#FFF, 0.9);
      }
    }
  }

  .timeline {
    position: fixed;
    bottom: 84px;
    left: 124px;
  }

  .timeline-item {
    width: 4px;
    height: 1px;
    background-color: rgba(#6FBBE7, 0.7);
    margin-bottom: 16px;
    position: relative;
    transition: all 100ms ease-in-out;

    &.is-clickable {
      cursor: pointer;
    }

    span {
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      @extend %h4;
      color: rgba(#FFF, 0.5);
      transition: inherit;
    }

    &.active {
      width: 16px;

      span {
        @extend %h2;
        color: #FFF;
        left: 24px;
      }
    }
  }
}
</style>
