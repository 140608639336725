<template>
  <transition name="slide-y-transition">
    <div class="sf-dialog" v-if="value">
      <button class="close" @click="$emit('input', false)">
        <img :src="require('@/assets/images/close.png')" alt="Close">
      </button>
      <h1 class="mb-24">Заказать звонок</h1>
      <form @submit.prevent="submit">
        <div class="input-group mb-4 mb-sm-4--l mb-sm-8 mb-md-8--l mb-md-16 mb-lg-16--l mb-xl-20--l">
          <input
            type="text"
            required
            placeholder="Как к вам обращаться?"
            name="user_name"
          />
          <span class="highlight"></span>
          <span class="bar"></span>
        </div>
        <div class="input-group mb-36 mb-sm-36--l mb-sm-32 mb-md-32--l mb-md-40 mb-lg-40--l mb-xl-56--l">
          <input
            type="text"
            required
            placeholder="Номер телефона"
            name="user_phone"
          />
          <span class="highlight"></span>
          <span class="bar"></span>
        </div>
        <div class="call-me-button">
          <button type="submit">Перезвоните мне</button>
        </div>
      </form>
    </div>
  </transition>
</template>

<script>
import emailjs from 'emailjs-com'
import swal from 'sweetalert'

export default {
  name: "SfDialog",
  props: {
    value: Boolean
  },
  methods: {
    async submit (e) {
      try {
        await emailjs.sendForm(
            'service_5hh0djj',
            'template_m70z3ou',
            e.target,
            'user_iZQf56je2HoIMwaeyJbbe'
        )
        swal({
          title: "Сообщение отправлено",
          text: "В ближайшее время наш менеджер свяжется с вами",
          icon: "success",
          button: "Закрыть",
        });
      } catch (e) {
        swal({
          title: "Упс! Что-то пошло не так",
          text: "Повторите попытку позже. Наши бородатые дяди-программисты уже работают над исправлением ситуации",
          icon: "error",
          button: "Закрыть",
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sf-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 640px;
  height: 400px;
  background: #EDF1F5;
  box-shadow: 0 4px 32px #63dcff;
  border-radius: 1px;
  z-index: 10;
  padding: 48px 24px 24px;

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    border: none;
    cursor: pointer;
  }

  h1 {
    text-align: center;
    color: #284F92;
  }

  form {
    text-align: center;
  }

  .input-group {
    width: 100%;
    max-width: 528px;
    margin: 0 auto;

    position: relative;

    input {
      @extend %h5;
      color: #888888;
      padding: 11px;
      display: block;
      width: 100%;
      border: none;
      border-bottom: 1px solid #D8D8D8;
      background: transparent;
      text-align: center;
      font-size: 11px/120%;
      letter-spacing: 3px;
      text-transform: uppercase;

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
        padding: 11px;
        font-size: 11px/120%;
        letter-spacing: 3px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
        padding: 18px;
        font-size: 14px/120%;
        letter-spacing: 3px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
        padding: 18px;
        font-size: 14px/120%;
        letter-spacing: 3px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
        padding: 15px;
        font-size: 16px/120%;
        letter-spacing: 4px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        padding: 15px;
        font-size: 16px/120%;
        letter-spacing: 4px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
        padding: 13px;
        font-size: 18px/120%;
        letter-spacing: 4px;
      }
    }

    input:focus {
      outline: none;
    }

    label {
      color: #999;
      font-size: 18px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 5px;
      top: 10px;
      transition: 0.2s ease all;
    }

    input:focus ~ label,
    input:valid ~ label {
      top: -20px;
      font-size: 14px;
      color: #5264AE;
    }

    .bar {
      position: relative;
      display: block;
      width: 100%;
    }

    .bar:before, .bar:after {
      content: '';
      height: 2px;
      width: 0;
      bottom: 1px;
      position: absolute;
      background: #5264AE;
      transition: 0.2s ease all;
    }

    .bar:before {
      left: 50%;
    }

    .bar:after {
      right: 50%;
    }

    /* active state */
    input:focus ~ .bar:before, input:focus ~ .bar:after {
      width: 50%;
    }

    /* HIGHLIGHTER ================================== */
    .highlight {
      position: absolute;
      height: 60%;
      width: 100%;
      top: 25%;
      left: 0;
      pointer-events: none;
      opacity: 0.5;
    }

    /* active state */
    input:focus ~ .highlight {
      -webkit-animation: inputHighlighter 0.3s ease;
      -moz-animation: inputHighlighter 0.3s ease;
      animation: inputHighlighter 0.3s ease;
    }

    /* ANIMATIONS ================ */
    @-webkit-keyframes inputHighlighter {
      from {
        background: #5264AE;
      }
      to {
        width: 0;
        background: transparent;
      }
    }
    @-moz-keyframes inputHighlighter {
      from {
        background: #5264AE;
      }
      to {
        width: 0;
        background: transparent;
      }
    }
    @keyframes inputHighlighter {
      from {
        background: #5264AE;
      }
      to {
        width: 0;
        background: transparent;
      }
    }
  }

  .call-me-button {
    margin: 0 auto;
    width: 236px;

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      width: 304px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
      width: 304px;
    }

    button {
      height: 40px;
      width: 100%;
      background: #40A0D7;
      border: 2px solid #40A0D7;
      box-sizing: border-box;
      border-radius: 1px;
      @extend %navigation;
      color: rgba(#FFFFFF, 0.8);
      cursor: pointer;
      transition: 200ms ease-in-out;

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
        height: 48px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        height: 48px;
      }

      &:hover {
        background: linear-gradient(0deg, #40A0D7, #40A0D7);
        box-shadow: 0 0 16px #63DCFF, 0 0 24px #63DCFF;
        color: #FFFFFF;
      }
    }
  }
}

.slide-y-transition {
  &-enter-active,
  &-leave-active {
    transition: 300ms cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  &-move {
    transition: transform 600ms;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-15px);
  }
}
</style>
