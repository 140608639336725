<template>
  <div class="earth" id="earth">
    <img :src="require('@/assets/images/earth.png')" alt="">
    <div class="earth-bg"></div>
  </div>
</template>

<script>
export default {
name: "SfEarth"
}
</script>

<style lang="scss" scoped>
#earth {
  position: fixed;
  width: 221px;
  height: 221px;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;
  pointer-events: none;
  transition: 300ms ease-in-out;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    right: 16px;
    left: unset;
    bottom: unset;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    width: 300px;
    height: 300px;
    bottom: -66px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
    width: 407px;
    height: 407px;
    bottom: 81px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    width: 500px;
    height: 500px;
    left: calc(50% + 60px);
  }

  img {
    width: 100%;
    height: 100%;
  }

  &.second-stage {
    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      right: unset;
      left: 16px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      left: 50%;
      transform: translate(-125%, -50%);
      bottom: unset;
      //right: calc(50% + 60px);
      //left: auto;
    }
  }

  &.hide {
    opacity: 0;
  }

  /* position: fixed;
  top: 50%;
  right: 18vw;
  width: 550px;
  height: 550px;
  transition: all .6s ease;
  transform: translateY(-50%) scale(1.3);

  .earth-bg {
    position: absolute;
    left: 60px;
    right: 60px;
    top: 60px;
    bottom: 60px;
    border-radius: 50%;
    z-index: -1;
    box-shadow: 0 0 20px 0 #b1daf0,
                0 0 200px 200px rgba(8, 141, 255, .2),
                0 0 20px 0 #b1daf0, 0 0 200px 100px rgba(8, 141, 255, .4);
    opacity: .9;
  }

  &.second-stage {
    transform: scale(1.5);
    top: 250px;
    right: 58vw;
  }

  &.hide {
    opacity: 0;
  } */
}
</style>