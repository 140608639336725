import Vue from 'vue'
import VueRouter from 'vue-router'

import IndexPage from '@/pages/IndexPage'
import HistoryPage from '@/pages/HistoryPage'
import PressCenterPage from '@/pages/PressCenterPage/index'
import ContactPage from '@/pages/ContactPage'
import NewsItemPage from '@/pages/NewsItemPage'
import ProductPage from '@/pages/ProductPage/index'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: IndexPage
    },
    {
      path: '/history',
      component: HistoryPage
    },
    {
      path: '/press-center',
      component: PressCenterPage
    },
    {
      path: '/contacts',
      component: ContactPage
    },
    {
      path: '/news-item',
      component: NewsItemPage
    },
    {
      path: '/products',
      component: ProductPage
    }
  ]
})
