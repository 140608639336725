<template>
  <div class="index-page">
    <SfMap :isShow="isShowMap" @is-show-map="(e) => { isShowMap = e }" />
    <SfDialog v-model="isShowDialog" />
    <ThirdSection
      :isShow="isShowThirdSection"
      @swipe="onSwipeHandler"
    />
    <FifthSection :isShow="isShowFifthSection" />
    <SfHeader @toggle="(e) => { isOpenMenu = e }" />
    <SfMenu :isOpenMenu="isOpenMenu" />
    <SfBackground />
    <SfSections
      @show-third-section="(e) => { isShowThirdSection = e }"
      @show-fifth-section="(e) => { isShowFifthSection = e }"
      @is-show-map="(e) => { isShowMap = e }"
      @click="(e) => { isShowDialog = e }"
      ref="sections"
    />
    <SfFooter />
    <SfEarth />
<!--    <SfAside />-->
  </div>
</template>

<script>
import SfHeader from '@/components/SfHeader'
import SfMenu from '@/components/SfMenu'
import SfBackground from '@/components/SfBackground'
import SfSections from '@/components/SfSections'
import ThirdSection from '@/components/other-sections/ThirdSection'
import FifthSection from '@/components/other-sections/FifthSection'
import SfFooter from '@/components/SfFooter'
import SfEarth from '@/components/SfEarth'
import SfMap from '@/components/SfMap'
import SfDialog from '@/components/SfDialog'
// import SfAside from '@/components/SfAside'

export default {
  name: 'App',
  components: {
    SfHeader,
    SfMenu,
    SfBackground,
    SfSections,
    ThirdSection,
    FifthSection,
    SfFooter,
    SfEarth,
    SfMap,
    SfDialog
    // SfAside
  },
  data () {
    return {
      isOpenMenu: false,
      isShowThirdSection: false,
      isShowFifthSection: false,
      isShowMap: false,
      isShowDialog: false
    }
  },
  methods: {
    onSwipeHandler () {
      console.log(this.$refs.sections)
    }
  }
}
</script>

<style>
</style>
