<template>
  <div class="sf-background">
    <div>
      <div class="stars"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SfBackground'
}
</script>

<style scoped lang="scss">
@function generateStar () {
  $result: '6px 1502px #FFF';

  @for $_ from 1 through 700 {
    $result: $result + ', ' + random(1920) + 'px ' + random(1920) + 'px #FFF';
  }

  @return #{$result};
}

$star: generateStar();

.sf-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);

  .stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $star;
    animation: animStar 500s linear infinite;

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      width: 1px;
      height: 1px;
      background: transparent;
      box-shadow: $star;
    }
  }
}

@keyframes animStar {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-2000px);
  }
}
</style>