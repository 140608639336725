<template>
  <transition name="slide-y-transition">
    <div class="sf-map" v-if="isShow">
      <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A2bf490efb951979e63f7862a2ab9fae289be0aeac44a8aed6151ec5684c4494a&amp;source=constructor" width="100%" frameborder="0"></iframe>
      <div class="contact-frame">
        <div class="contact-frame__content">
          <div class="flex xs12 l-sm12 l-md12 back" @click="() => { $emit('is-show-map', false) }">
            <SvgIcon name="back" />
            <span>Вернуться</span>
          </div>
          <div class="flex xs12 l-sm12 sm12 l-md12">
            <h1 class="title">
              <span>Свяжитесь</span>
              <span>с нами</span>
            </h1>
          </div>
          <div class="flex xs6 l-sm12 sm12 l-md12 contact-item">
            <div class="city mb-4 mb-sm-4--l mb-sm-12 mb-md-12--l mb-xl-24--l">
              Москва&nbsp;
              <span class="ml-8 ml-sm-8--l ml-sm-12 ml-md-12--l ml-xl-16--l">База хранения</span>
            </div>
            <div class="caption mb-4">Адрес:</div>
            <div class="value mb-8">Московская область, Пушкинский р-н, пос. Лесные поляны, ул. Новая, влад. 7А</div>
<!--            <div class="caption mb-4">Телефон:</div>-->
<!--            <div class="value mb-8">-->
<!--              <a href="tel:+79453332211">+7 945 333 22 11</a>-->
<!--            </div>-->
<!--            <div class="caption mb-4">Эл. почта</div>-->
<!--            <div class="value mb-12 mb-sm-12&#45;&#45;l mb-lg-16&#45;&#45;l mb-xl-20&#45;&#45;l">-->
<!--              <a href="mailto:moscow_info@sfyros.ru">moscow_info@sfyros.ru</a>-->
<!--            </div>-->
          </div>
          <div class="flex xs6 l-sm12 sm12 l-md12 contact-item">
            <div class="city mb-4 mb-sm-4--l mb-sm-12 mb-md-12--l mb-xl-24--l">
              Пермь&nbsp;
              <span class="ml-8 ml-sm-8--l ml-sm-12 ml-md-12--l ml-xl-16--l">офис</span>
            </div>
            <div class="caption mb-4">Адрес:</div>
            <div class="value mb-8">шоссе Космонавтов, д. 111, корпус 27, офис 322</div>
            <div class="caption mb-4">Телефон:</div>
            <div class="value mb-8">
              <a href="tel:+73422162525">8 (342) 216-25-25</a>
            </div>
            <div class="caption mb-4">Эл. почта</div>
            <div class="value mb-12 mb-sm-12--l mb-lg-16--l mb-xl-20--l">
              <a href="mailto:sales@sfayros.com">sales@sfayros.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SvgIcon from '@/components/SvgIcon'

export default {
  name: "SfMap",
  components: {
    SvgIcon
  },
  props: {
    isShow: Boolean
  }
}
</script>

<style lang="scss" scoped>
.sf-map {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #FFF;

  iframe {
    width: 100%;
    height: 100%;
  }

  .contact-frame {
    background: #FFFFFF;
    box-shadow: 0 0 20px rgba(34, 34, 34, 0.07);
    position: fixed;
    z-index: 11;

    bottom: 0;
    left: 0;
    right: 0;
    height: 189px;

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      bottom: 0;
      left: 0;
      top: 0;
      height: auto;
      width: 160px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
      top: 8px;
      left: 8px;
      width: 189px;
      height: 438px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      top: 20px;
      left: 20px;
      width: 189px;
      height: 438px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
      width: 260px;
      height: 564px;
      top: 40px;
      left: 40px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      width: 260px;
      height: 564px;
      top: 40px;
      left: 40px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      width: 300px;
      height: 644px;
    }
  }

  .contact-frame__content {
    background: url("~@/assets/images/map-dot.png") no-repeat;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      padding: 16px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
      padding: 22px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      padding: 22px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
      padding: 32px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      padding: 32px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      padding: 32px;
    }

    .back {
      display: flex;
      align-items: center;
      @extend %navigation;
      color: rgba(#265295, 0.5);
      cursor: pointer;

      svg {
        width: 16px;
      }

      span {
        display: block;
        margin-left: 16px;
      }
    }

    h1.title span {
      display: block;
      color: #284F92;

      &:first-child {
        @extend %h3;
        color: #284F92;
      }
    }
  }

  .contact-item {
    .city {
      @extend %h4;
      color: #284F92;

      span {
        @extend %body-font;
        color: #284F92;
      }
    }

    .caption {
      @extend %navigation-2;
      color: #B7B7B7;
    }

    .value {
      @extend %body-font;
      color: rgba(#000000, 0.9);
    }

    .value a {
      color: inherit;
      text-decoration: none;
    }

    .to-map {
      display: flex;
      align-items: center;
      color: #40A0D7;
      cursor: pointer;
    }

    .to-map svg {
      width: 24px;
      height: 24px;
    }

    .to-map span {
      @extend %h5;
      color: #40A0D7;
    }
  }
}

.slide-y-transition {
  &-enter-active,
  &-leave-active {
    transition: 300ms cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  &-move {
    transition: transform 600ms;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-15px);
  }
}
</style>
