<template>
  <full-page :options="options">
    <FirstSection @click="(e) => { $emit('click', e) }" />
    <SecondSection />
    <ThirdSection />
    <FourthSection />
    <FifthSection />
    <SixthSection />
    <FooterSection @is-show-map="(e) => { $emit('is-show-map', e) }" />
  </full-page>
</template>

<script>
import FirstSection from './sections/FirstSection'
import SecondSection from './sections/SecondSection'
import ThirdSection from '@/components/sections/ThirdSection'
import FourthSection from '@/components/sections/FourthSection'
import FifthSection from '@/components/sections/FifthSection'
import SixthSection from '@/components/sections/SixthSection'
import FooterSection from '@/components/sections/FooterSection'

// const clearAnimation = index => {
//   const section = document.querySelectorAll('.fullpage-wrapper > .section')[index]
//   section.querySelectorAll('.animated').forEach(item => {
//     item.classList.remove('animate')
//     item.classList.remove(item.dataset.animation)
//     item.classList.add('animate')
//   })
// }

// const playAnimation = (index = 0) => {
//   const section = document.querySelectorAll('.fullpage-wrapper > .section')[index]
//   section.querySelectorAll('.animate').forEach(item => {
//     item.classList.remove('animate')
//     item.classList.add(item.dataset.animation)
//     item.classList.add('animated')
//   })
// }

// const defineOtherSection = (nextIndex, prevIndex) => {
//   if (nextIndex === 1) {
//     document.querySelector('#earth').classList.add('second-stage')
//   }
//
//   if (prevIndex === 1 && nextIndex === 0) {
//     document.querySelector('#earth').classList.remove('second-stage')
//   }
//
//   if (nextIndex === 2) {
//     document.querySelector('#third-section').classList.add('show')
//   }
//
//   if (prevIndex === 2) {
//     document.querySelector('#third-section').classList.remove('show')
//   }
//
//   if (nextIndex === 4) {
//     document.querySelector('#fifth-section').classList.add('show')
//   }
//
//   if (prevIndex === 4) {
//     document.querySelector('#fifth-section').classList.remove('show')
//   }
//
//   if (nextIndex === 6) {
//     document.querySelector('footer').classList.add('active')
//     document.querySelector('#earth').classList.add('hide')
//   }
//
//   if (prevIndex === 6) {
//     document.querySelector('footer').classList.remove('active')
//     document.querySelector('#earth').classList.remove('hide')
//   }
// }
//
// const playSectionAnimation = (origin, dest) => {
//   clearAnimation(origin.index)
//   playAnimation(dest.index)
//   defineOtherSection(dest.index, origin.index)
// }

export default {
  name: 'SfSections',
  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
    FifthSection,
    SixthSection,
    FooterSection
  },
  data () {
    return {
      // isShowThirdSection: false,
      options: {
        licenseKey: 'A0B80391-497D4154-8EFB8EFD-FEB00375',
        onLeave: this.playSectionAnimation,
        anchors:['1', '2', '3', '4', '5', '6'],
        navigation: false,
        // normalScrollElements: '#footer-section'
      }
    }
  },
  methods: {
    clearAnimation (index) {
      const section = document.querySelectorAll('.fullpage-wrapper > .section')[index]
      section.querySelectorAll('.animated').forEach(item => {
        item.classList.remove('animate')
        item.classList.remove(item.dataset.animation)
        item.classList.add('animate')
      })
    },
    playAnimation (index = 0) {
      const section = document.querySelectorAll('.fullpage-wrapper > .section')[index]
      section.querySelectorAll('.animate').forEach(item => {
        item.classList.remove('animate')
        item.classList.add(item.dataset.animation)
        item.classList.add('animated')
      })
    },
    defineOtherSection (nextIndex, prevIndex) {
      if (nextIndex >= 1) {
        document.querySelector('#earth').classList.add('second-stage')
      }

      if (prevIndex === 1 && nextIndex === 0) {
        document.querySelector('#earth').classList.remove('second-stage')
      }

      if (nextIndex === 2) {
        this.$emit('show-third-section', true)
        // this.isShowThirdSection = true
        // document.querySelector('#third-section').classList.add('show')
      }

      if (prevIndex === 2) {
        this.$emit('show-third-section', false)
        // this.isShowThirdSection = false
        // document.querySelector('#third-section').classList.remove('show')
      }

      if (nextIndex === 4) {
        this.$emit('show-fifth-section', true)
        // document.querySelector('#fifth-section').classList.add('show')
      }

      if (prevIndex === 4) {
        this.$emit('show-fifth-section', false)
        // document.querySelector('#fifth-section').classList.remove('show')
      }

      if (nextIndex === 6) {
        document.querySelector('footer').classList.add('active')
        document.querySelector('#earth').classList.add('hide')
      }

      if (prevIndex === 6) {
        document.querySelector('footer').classList.remove('active')
        document.querySelector('#earth').classList.remove('hide')
      }
    },
    playSectionAnimation (origin, dest) {
      this.clearAnimation(origin.index)
      this.playAnimation(dest.index)
      this.defineOtherSection(dest.index, origin.index)
    }
  }
}
</script>

<style lang="scss">
.section {
  overflow: hidden;

  .fp-tableCell {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      //justify-content: center;
    }
  }

  .container {
    padding: {
      top: 55px;
      right: 38px;
      left: 45px;
    };

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      padding: {
        top: 47px;
        bottom: 26px;
        left: 48px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
      padding: {
        top: 88px;
        right: 66px;
        left: 70px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      padding: {
        top: 112px;
        bottom: 76px;
        left: 66px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
      padding: {
        top: 128px;
        right: 122px;
        left: 122px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      padding: {
        top: 144px;
        bottom: 88px;
        left: 76px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      //padding: {
      //  top: 148px;
      //  bottom: 133px;
      //};

      max-width: map-get($grid-breakpoint, 'xl');
      margin: auto;
    }

    h1.title {
      color: #40A0D7;
      text-shadow: 0 0 32px rgba(82, 194, 255, 0.5);
    }

    p.description {
      @extend %body-font;
      color: rgba(#FFFFFF, 0.9);
    }
  }
}
</style>
