<template>
  <div class="section" data-anchor="3" data-section="2"></div>
</template>

<script>
export default {
  name: "ThirdSection"
}
</script>

<style lang="scss" scoped>
</style>
