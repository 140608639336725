<template>
  <div :class="['sf-menu', { 'open': isOpenMenu }]">
    <div class="container">
      <div class="row">
        <div class="flex xs12 md6">
          <ul>
            <li>
              <router-link to="/">О компании</router-link>
            </li>
            <li>
              <router-link to="/history">История компании</router-link>
            </li>
            <li>
              <router-link to="/press-center">Пресс-центр</router-link>
            </li>
            <li><router-link to="/products">Продукты</router-link></li>
            <li>
              <router-link to="/contacts">Контакты</router-link>
            </li>
          </ul>
        </div>
        <div class="flex xs12 md6">
          <div class="caption">Вы можете позвонить:</div>
          <div class="value">
            <a href="tel:73422162525">8 (342) 216-25-25</a>
          </div>
          <div class="caption">Или оставить заявку:</div>
          <div class="value">
            <a href="mailto:sales@sfayros.com">sales@sfayros.com</a>
          </div>
        </div>
        <div class="flex xs12">
          <div class="row">
            <div class="flex xs12 lg6 presentation">
              <img :src="require('@/assets/images/pdf.svg')">
              <a href="#">Скачать презентацию</a>
            </div>
            <div class="flex xs12 lg6">
              <form class="feedback">
                <div class="row">
                  <div class="flex xs6">
                    <input type="text" id="feedback-form">
                    <label for="feedback-form">Номер телефона</label>
                  </div>
                  <div class="flex xs6">
                    <button>
                      Перезвоните мне
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="flex xs12 copy">
          © СФАЙРОС 2019. Все права защищены.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SfMenu',
  props: {
    isOpenMenu: Boolean
  }
}
</script>

<style lang="scss">
.sf-menu {
  @include absolute-fill;
  position: fixed;
  z-index: 1;
  background: radial-gradient(35.49% 68.62% at 65.09% 48.47%, #265295 0%, #041B3D 100%);
  transition: all 200ms ease-in-out;
  padding: 56px 16px 8px;
  opacity: 0;
  pointer-events: none;

  @include min-breakpoint('sm') {
    padding-top: 100px;
    padding-left: 38px;
    padding-right: 38px;
  }

  @include min-breakpoint('md') {
    padding-top: 120px;
    padding-left: 96px;
    padding-right: 96px;
  }

  @include min-breakpoint('lg') {
    padding-top: 146px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ul li {
    margin: 20px 0;
    transition: all 100ms ease-in-out;
    cursor: pointer;
  }

  ul li a {
    @extend %h2;
    color: #FFFFFF;
    text-decoration: none;
    transition: inherit;

    &:hover {
      text-shadow: 0 0 8px #B1DAF0, 0 0 32px #2DB3FC;
    }
  }

  .caption {
    @extend %h4-regular;
    color: #60B3E0;
    margin-top: 20px;
    opacity: 0.8;
  }

  .value a {
    @extend %h2;
    color: #FFFFFF;
    text-decoration: none;
    transition: inherit;

    &:hover {
      text-shadow: 0 0 8px #B1DAF0, 0 0 32px #2DB3FC;
    }
  }

  .presentation {
    display: flex;
    align-items: center;
    margin-top: 16px;

    img {
      margin-right: 12px;
    }

    a {
      @extend %navigation;
      color: #40A0D7;
      text-decoration-style: dotted;
    }
  }

  .feedback {
    position: relative;
    margin-top: 32px;

    > * {
      height: 40px;

      @include min-breakpoint('md') {
        height: 48px;
      }
    }

    input {
      height: 100%;
      width: 100%;
      outline: none;
      padding: 0 14px;
    }

    label {
      position: absolute;
      @extend %caption;
      top: -16px;
      left: 15px;
      width: 100%;
      color: rgba(#FFFFFF, 0.8);
    }

    button {
      width: 100%;
      height: 100%;
      text-align: center;
      @extend %navigation;
      background: transparent;
      border: 2px solid #40A0D7;
      color: rgba(#FFFFFF, 0.8);
      cursor: pointer;
      transition: all 100ms ease-in-out;
      border-radius: 1px;

      &:hover {
        box-shadow: 0 0 16px #63DCFF, 0 0 24px #63DCFF;
      }
    }
  }

  .copy {
    @extend %caption-regular;
    color: rgba(#FFFFFF, 0.3);
    margin-top: 16px;
    text-align: center;
  }

  &.open {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>
