<template>
  <div class="section" data-section="2" data-anchor="2">
    <div class="container full">
      <div class="row no-gutters">
        <div class="flex xs12 l-sm6 l-offset-sm6">
          <h1 class="title mb-8 mb-sm-8--l mb-sm-24 mb-md-24--l mb-xl-32--l">Поставщики</h1>
          <p class="description mb-0 mb-sm-12--l mb-sm-16 mb-md-16--l mb-md-28 mb-lg-28--l mb-xl-32--l">
            Имеем устойчивые многолетние связи со всеми основными производителями сжиженного газа на территории России и
            Казахстана: ЛУКОЙЛ, ГАЗПРОМ, СИБУР, ТАТНЕФТЬ, РОСНЕФТЬ, НОВАТЭК
          </p>
          <div class="partners row align-items-end">
            <div class="partner-item flex xs4">
              <img class="inactive" src="@/assets/images/partners/lukoil.svg" alt="">
              <img class="active" src="@/assets/images/partners/lukoil-active.svg" alt="">
            </div>
            <div class="partner-item flex xs4">
              <img class="inactive" src="@/assets/images/partners/gazprom.svg" alt="">
              <img class="active" src="@/assets/images/partners/gazprom-active.svg" alt="">
            </div>
            <div class="partner-item flex xs4">
              <img class="inactive" src="@/assets/images/partners/sibur.svg" alt="">
              <img class="active" src="@/assets/images/partners/sibur-active.svg" alt="">
            </div>
            <div class="partner-item flex xs4">
              <img class="inactive" src="@/assets/images/partners/tatneft.svg" alt="">
              <img class="active" src="@/assets/images/partners/tatneft-active.svg" alt="">
            </div>
            <div class="partner-item flex xs4">
              <img class="inactive" src="@/assets/images/partners/rosneft.svg" alt="">
              <img class="active" src="@/assets/images/partners/rosneft-active.svg" alt="">
            </div>
            <div class="partner-item flex xs4">
              <img class="inactive" src="@/assets/images/partners/novatek.png" alt="">
              <img class="active" src="@/assets/images/partners/novatek-active.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondSection"
}
</script>

<style lang="scss" scoped>
.section[data-section="2"] {
  ::v-deep .fp-tableCell {
    display: flex;
    flex-direction: column;
  }

  .container {
    padding: {
      top: 55px;
      right: 38px;
      left: 45px;
    };

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      padding: {
        top: 47px;
        bottom: 26px;
        left: 48px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
      padding: {
        top: 88px;
        right: 66px;
        left: 70px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      padding: {
        top: 112px;
        bottom: 76px;
        left: 66px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
      padding: {
        top: 128px;
        right: 122px;
        left: 122px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      padding: {
        top: 144px;
        bottom: 88px;
        left: 76px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      //padding: {
      //  top: 148px;
      //  bottom: 133px;
      //};

      max-width: map-get($grid-breakpoint, 'xl');
      margin: auto;
    }

    h1.title {
      color: #40A0D7;
      text-shadow: 0 0 32px rgba(82, 194, 255, 0.5);
    }

    p.description {
      @extend %body-font;
      color: rgba(#FFFFFF, 0.9);
    }

    .partner-item {
      position: relative;
    }

    .partner-item img {
      width: 100% !important;
    }

    .partner-item .active,
    .partner-item .inactive {
      transition: opacity 200ms ease-in-out;
    }

    .partner-item .active {
      width: auto;
      opacity: 0;
    }

    .partner-item .inactive {
      position: absolute;
      opacity: 1;
    }

    .partner-item:hover .active {
      opacity: 1;
    }

    .partner-item:hover .inactive {
      opacity: 0;
    }
  }
}
</style>
