export default {
  '2001': {
    text: `
    <p class="subtitle mb-sm-8 mb-md-24">9 февраля 2001 года было создано ООО «Сфайрос»</p>
    <p class="regular mb-8 mb-sm-24">Первые поставки крупнооптовых партий СУГ с Сургутского ЗСК</p>
    <p class="subtitle mb-sm-8 mb-md-24">В России</p>
    <p class="regular mb-8 mb-sm-24">
      <ul>
        <li>23 января 2001 года президент Путин принял решение о частичном выводе российских войск из республики Чечня</li>
        <li>Компания Лукойл проходит сертификацию по международным стандартам безопасности, приобретение Нижегородского НПЗ</li>
      </ul>
    </p>
    `
  },
  '2002': {
    text: `
      <p class="subtitle mb-sm-8 mb-md-24">2 июня 2002 года - начало содрудничества с ПАО ЛУКОЙЛ</p>
      <p class="subtitle mb-sm-8 mb-md-24">В России</p>
      <p class="regular mb-8 mb-sm-24">
        <ul>
          <li>Создана организация договора о коллективной безопасности (ОДКБ). В неё вошли Россия, Армения, Белоруссия, Казахстан, Кыргызстан, Таджикистан, Узбекистан</li>
          <li>Компания НОВАТЭК начинает поставки природного газа потребителям по прямым договорам</li>
        </ul>
      </p>
    `
  },
  '2007': {
    text: `
    <p class="subtitle mb-sm-8 mb-md-24">2007 год. достижение объёма реализации свыше 100 000 тонн в год</p>
    <p class="subtitle mb-sm-8 mb-md-24">В России</p>
      <p class="regular mb-8 mb-sm-24">
        <ul>
          <li>Начались поставки российского газа по «Голубому потоку в Турцию»</li>
          <li>Международный олимпийский комитет определил Сочи местом проведения XXII зимних Олимпийских игр 2014 г</li>
        </ul>
      </p>
    `
  },
  '2011': {
    text: `
    <p class="subtitle mb-sm-8 mb-md-24">2011 год. Начало регулярных поставок СУГ в Московский регион</p>
    <p class="subtitle mb-sm-8 mb-md-24">В России</p>
      <p class="regular mb-8 mb-sm-24">
        <ul>
          <li>Милиция официально прекратила своё существование – стала полицией</li>
          <li>Жители России в последний раз перешли на летнее время</li>
          <li>50-летие первого полёта человека в космос отметили в России и в мире</li>
          <li>Запущена в эксплуатацию первая нитка магистрального газопровода «Северный поток» между Россией и Германией</li>
        </ul>
      </p>
    `
  },
  '2016': {
    text: `
      <p class="subtitle mb-sm-8 mb-md-24">2016 год. Осуществляется перевалка СУГ на терминалах хранения на западном и европейском направлениях</p>
      <p class="subtitle mb-sm-8 mb-md-24">В России</p>
      <p class="regular mb-8 mb-sm-24">
        <ul>
        <li>Ухудшение российско-турецких отношений. Вступил в силу запрет на ввоз в Россию турецких товаров, прекратились частные воздушные перевозки</li>
        <li>В России появилась новая силовая структура – Национальная гвардия (Росгвардия) на базе внутренних войск МВД</li>
        <li>Завершение сделки пол приватизации «Роснефти»</li>
        </ul>
      </p>
    `
  },
  '2017': {
    text: `
      <p class="subtitle mb-sm-8 mb-md-24">2017 год. Создание экспортного направления</p>
      <p class="subtitle mb-sm-8 mb-md-24">В России</p>
      <p class="regular mb-8 mb-sm-24">
        <ul>
        <li>Вступил в силу Федеральный закон, определяющий порядок реновации жилого фонда в Москве и программу реновации</li>
        <li>Волна «телефонного терроризма» в России – массовые информационно-психологические провокации</li>
        <li>В 2017 году Россия вошла в тройку стран-лидеров по объёму покупок на AliExpress</li>
        <li>Россия приняла новую программу «Цифровая экономика», на период до 2025 года, направленную на устранение факторов, не позволяющих России перейти в группу стран-лидеров цифровых преобразований, с ежегодным предполагаемым бюджетом в 1,8 млрд $</li>
        <li>Компанией НОВАТЭК начато производство сжиженного природного газа на первой технологической линии завода «Ямал СПГ»</li>
        </ul>
      </p>
    `
  },
  '2018': {
    text: `
    <p class="subtitle mb-sm-8 mb-md-24">2018 год. начало импортных поставок СУГ и хладагентов</p>
    <p class="subtitle mb-sm-8 mb-md-24">В России</p>
      <p class="regular mb-8 mb-sm-24">
        <ul>
        <li>Открытие Крымского моста</li>
        <li>Летом Россия впервые в своей истории принимала чемпионат мира по футболу. Турнир проводился на 12 стадионах в 11 городах</li>
        </ul>
      </p>
    `
  },
  '2021': {
    text: `
    <p class="subtitle mb-sm-8 mb-md-24">2021 г. Приобретение Ногинской ГРС. Увеличение доли рынка МО</p>
    `
  }
}
