<template>
  <div class="section" data-section="6" data-anchor="6">
    <div class="container">
      <div class="row">
        <div class="flex xs12 l-sm6 l-offset-sm6">
          <h1 class="title mb-8 mb-sm-4--l mb-sm-8 mb-md-8--l mb-md-16 mb-lg-24--l mb-xl-32--l">Наши зарубежные партнёры</h1>
          <h4 class="subtitle mb-4 mb-sm-4--l mb-sm-8 mb-md-8--l">Экспорт</h4>
          <p class="description mb-8 mb-sm-8--l mb-md-12--l mb-md-20 mb-lg-16--l mb-xl-32--l">
            Осуществляем поставки СУГ в страны Восточной Европы и Таможенного союза по средствам железнодорожного и
            автотранспорта. Проводим полное таможенное оформление грузов
          </p>
          <h4 class="subtitle mb-4 mb-sm-4--l mb-sm-8 mb-md-8--l">Импорт</h4>
          <p class="description mb-16 mb-sm-12--l mb-sm-24 mb-md-20--l mb-md-40 mb-lg-32--l mb-xl-20--l">
            Осуществляем закупки специализированных газов (хладагентов) и сопутствующих комплектующих в странах Юго-Восточной Азии
          </p>
          <div class="features mb-16 mb-sm-12--l mb-sm-24 mb-md-28--l mb-md-32 mb-lg-32--l">
            <div class="feature-item">
              <div class="icon mb-4 mb-sm-4--l mb-md-12 mb-lg-12--l">
                <SvgIcon name="transport" />
              </div>
              <div class="title">Везём и морем и сушей</div>
            </div>
            <div class="feature-item">
              <div class="icon mb-4 mb-sm-4--l mb-md-12 mb-lg-12--l">
                <SvgIcon name="rouble" />
              </div>
              <div class="title">Гибкие условия оплаты</div>
            </div>
            <div class="feature-item">
              <div class="icon mb-4 mb-sm-4--l mb-md-12 mb-lg-12--l">
                <SvgIcon name="refrigerants" />
              </div>
              <div class="title">Хладагенты</div>
            </div>
          </div>
          <div class="order">
            <a href="https://sfayros.com/" target="_blank" rel="noopener">
              <SvgIcon name="gas" class="mr-8" />
              <span>
                Заказать хладагенты
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon'

export default {
  name: "SecondSection",
  components: {
    SvgIcon
  }
}
</script>

<style lang="scss" scoped>
.section[data-section="6"] {
  .container {
    h4.subtitle {
      color: rgba(#FFFFFF, 0.9);
    }

    .features {
      display: flex;
    }

    .feature-item {
      width: 100%;
      max-width: (100% / 3);
      flex-basis: (100% / 3);
    }

    .feature-item .icon {
      color: #5AACE8;
    }

    .feature-item .icon svg {
      width: 24px;
      height: 24px;

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
        width: 28px;
        height: 28px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
        width: 28px;
        height: 28px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
        width: 32px;
        height: 32px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        width: 32px;
        height: 32px;
      }
    }

    .feature-item .title {
      @extend %h5;
      color: rgba(#FFFFFF, 0.9);
    }

    .order {
      @extend %navigation;
      color: rgba(#FFFFFF, 0.5);
    }

    .order a {
      display: flex;
      align-items: center;
      color: inherit;
      text-decoration: none;
      transition: color 100ms ease-in-out;
    }

    .order a svg {
      width: 24px;
      height: 24px;
    }

    .order:hover {
      color: rgba(#FFFFFF, 0.9);
    }
  }
}
</style>
